import React, { useState, useEffect } from 'react';
import * as yup from 'yup';
import { Form } from 'react-bootstrap';
import { useHistory, Link, Redirect } from 'react-router-dom';
import { Navbar } from '../../../components/Navbar';
import { Sidebar } from '../../../components/Sidebar';
import { LogOutModal } from '../../../components/LogOutModal';
import api from '../../../config/configApi';

export const AddUser = () => {
    const history = useHistory();

    const [user, setUser] = useState({
        nome: '',
        unidadeId:'',
        email: '',
        password: '',
        empresaId: '',
        equipeId: '',
        perfilId: '',
        celular: '',
        outraId: '',
        cep: '',
        logradouro: '',
        numero: '',
        complemento: '',
        bairro: '',
        cidade: '',
        datanascimento: ''
    });

    const [status, setStatus] = useState({
        type: '',
        mensagem: ''
    });

  const [unidades, setUnidades] = useState([]);

    const valueInput = e => setUser({ ...user, [e.target.name]: e.target.value });

    const addUser = async e => {
        e.preventDefault();

        if (!(await validate())) return;

        const headers = {
            'headers': {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        };

        await api.post('/user/user', user, headers)
            .then((response) => {
                setStatus({
                    type: 'success',
                    mensagem: response.data.mensagem
                });

                alert("Usuário Cadastrado com sucesso")
                return history.push("/users/");
            }).catch((err) => {
                if (err.response) {
                    setStatus({
                        type: 'error',
                        mensagem: err.response.data.mensagem
                    });
                } else {
                    setStatus({
                        type: 'error',
                        mensagem: "Erro: Tente novamente!"
                    });
                }
            });
    }

    async function validate() {
        let schema = yup.object().shape({
            password: yup.string("Erro: Necessário preencher o campo senha!")
                .required("Erro: Necessário preencher o campo senha!")
                .min(6, "Erro: A senha deve ter no mínimo 6 caracteres!"),
            email: yup.string("Erro: Necessário preencher o campo e-mail!")
                .email("Erro: Necessário preencher o campo e-mail!")
                .required("Erro: Necessário preencher o campo e-mail!"),
            nome: yup.string("Erro: Necessário preencher o campo nome!")
                .required("Erro: Necessário preencher o campo nome!")
        });

        try {
            await schema.validate({
                nome: user.nome,
                email: user.email,
                password: user.password,
            });
            return true;
        } catch (err) {
            setStatus({
                type: 'error',
                mensagem: err.errors
            });
            return false;
        }
    }

   const getUnidades = async () => {
    try {
      const requestHeaders = {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      };
      const { data } = await api.get('/unidade/unidades', {
        headers: requestHeaders,
      });
      setUnidades(data.unidades);
    } catch (error) {
      setStatus({
        type: 'error',
        mensagem: 'Não foi possível carregar as unidades',
      });
    }
  };

  useEffect(() => {
    getUnidades();
  }, []);


    return (<>
        <div id="wrapper">
            <Sidebar />
            <div id="content-wrapper" className="d-flex flex-column">
                <div id="content">
                    <Navbar />
                    <div className="container-fluid">
                        <div class="card shadow mb-4">
                            <div class="card-header py-3">

                            <div className="alert-content-adm">
                                        {status.type === 'redWarning' ?
                                            <Redirect to={{
                                                pathname: '/users',
                                                state: {
                                                    type: "error",
                                                    mensagem: status.mensagem
                                                }
                                            }} /> : ""}
                                        {status.type === 'redSuccess' ? <Redirect to={{
                                            pathname: '/users',
                                            state: {
                                                type: "success",
                                                mensagem: status.mensagem
                                            }
                                        }} /> : ""}
                                        {status.type === 'error' ? <p className="alert-danger">{status.mensagem}</p> : ""}
                                    </div>


                                <div className="row">

                                    <div className="col-xl-10 col-md-6 mb-4">
                                        <h6 class="m-0 font-weight-bold text-primary">Cadastrar Usuário</h6>
                                    </div>

                                    <div className="col-xl-2 col-md-6 mb-4">

                                        <Link to="/users"><button type="button" className="btn btn-info btn-block">Listar</button></Link>{" "}

                                    </div>
                                </div>



                            </div>
                            <div class="card-body">



                                <form onSubmit={addUser} className="form-adm">
                                    <div class="form-row">

                                        <div class="form-group col-md-4">
                                            <label for="inputPassword4">Nome</label>
                                            <input type="text" name="nome" id="nome" className="form-control" placeholder="Nome completo do usuário" onChange={valueInput} />
                                        </div>

                                        
                                <Form.Group className="col-lg-2 col-md-6">
                                    <Form.Label>Unidade</Form.Label>
                                    <Form.Select
                                        aria-label="Selecione uma unidade"
                                        name="unidadeId"
                                        value={user.unidadeId}
                                        onChange={valueInput}
                                        required
                                        >
                                        <option value="">Selecione uma Unidade</option>
                                        {unidades.map((unidade) => (
                                            <option key={unidade.id} value={unidade.id}>
                                            {unidade.nome}
                                            </option>
                                        ))}
                                    </Form.Select>
                                </Form.Group>

                                        
                                        <div class="form-group col-md-4">
                                            <label for="inputEmail4">Email</label>
                                            <input type="email" name="email" id="email" className="form-control" placeholder="Melhor e-mail do usuário" onChange={valueInput} />
                                        </div>

                                        <div class="form-group col-md-2">
                                            <label for="inputPassword4">Nascimento</label>
                                            <input type="text" name="datanascimento" id="datanascimento" className="form-control" onChange={valueInput} />
                                        </div>
                                    </div>

                                    <div class="form-row">
                                        <div class="form-group col-md-1">
                                            <label for="inputEmail4">CEP</label>
                                            <input type="text" name="cep" id="cep" className="form-control" autoComplete="on" onChange={valueInput} />
                                        </div>
                                        <div class="form-group col-md-3">
                                            <label for="inputPassword4">Logradouro</label>
                                            <input type="text" name="logradouro" id="logradouro" className="form-control" autoComplete="on" onChange={valueInput} />
                                        </div>
                                        <div class="form-group col-md-1">
                                            <label for="inputPassword4">Número</label>
                                            <input type="text" name="numero" id="numero" className="form-control" autoComplete="on" onChange={valueInput} />
                                        </div>
                                        <div class="form-group col-md-2">
                                            <label for="inputPassword4">Complemento</label>
                                            <input type="text" name="complemento" id="complemento" className="form-control" autoComplete="on" onChange={valueInput} />
                                        </div>

                                        <div class="form-group col-md-2">
                                            <label for="inputPassword4">Bairro</label>
                                            <input type="text" name="bairro" id="bairro" className="form-control" autoComplete="on" onChange={valueInput} />
                                        </div>

                                        <div class="form-group col-md-3">
                                            <label for="inputPassword4">Cidade</label>
                                            <input type="text" name="cidade" id="cidade" className="form-control" autoComplete="on" onChange={valueInput} />
                                        </div>
                                    </div>


                                    <div class="form-row">
                                        <div class="form-group col-md-2">
                                            <label for="inputEmail4">Empresa</label>

                                            <select class="custom-select my-1 mr-sm-2" name="empresaId" id="empresaId" placeholder="Empresa Usuário" onChange={valueInput} >
                                                <option value="">Selecione o Perfil</option>
                                                <option value="1">Marcelo Manhaes</option>
                                            </select>
                                        </div>

                                        <div class="form-group col-md-2">
                                            <label for="inputEmail4">Equipe</label>
                                            <select class="custom-select my-1 mr-sm-2" name="equipeId" id="equipeId" placeholder="Equipe Usuário" onChange={valueInput} >
                                                <option value="">Selecione o Equipe</option>
                                                <option value="1">Padrão</option>
                                            </select>


                                        </div>


                                        <div class="form-group col-md-2">
                                                <label for="inputEmail4">Outra</label>
                                                <select class="custom-select my-1 mr-sm-2" name="outraId" id="outraId" placeholder="Outra Usuário" onChange={valueInput} >
                                                    <option value="">Selecione o Outra</option>
                                                    <option value="1">Outra</option>
                                                </select>
                                                
                                                
                                            </div>    
                                        <div class="form-group col-md-2">
                                            <label for="inputEmail4">Perfil</label>
                                            <select class="custom-select my-1 mr-sm-2" name="perfilId" id="perfilId" placeholder="Perfil Usuário" onChange={valueInput} >
                                                <option value="">Selecione o Perfil</option>
                                                <option value="1">Master</option>
                                                
                                            </select>

                                        </div>

                                        <div class="form-group col-md-2">
                                            <label for="inputPassword4">Celular</label>
                                            <input type="text" name="celular" id="celular" className="form-control" autoComplete="on" onChange={valueInput} />
                                        </div>

                                        <div class="form-group col-md-2">
                                            <label for="inputEmail4">Senha</label>
                                            <input type="password" name="password" id="password" className="form-control" placeholder="Senha para acessar o sistema" autoComplete="on" onChange={valueInput} />

                                        </div>
                                    </div>



                                    <div class="form-group row">

                                        <button type="submit" className="btn btn-success  btn-block">Salvar</button>
                                    </div>


                                </form>
                            </div>


                        </div>

                    </div>
                </div>
            </div>
        </div>
        <LogOutModal />
    </>);
};